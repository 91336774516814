import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { conexion } from '../../conexion';
import { Observable } from 'rxjs';
import { LoginI } from 'src/app/models/login.interface';
import { ResponseI } from 'src/app/models/response.interface';


@Injectable({
  providedIn: 'root'
})

export class LoginService
{

  url:string = conexion.url;

  constructor(private http: HttpClient) { }

  loginByUser(form:LoginI):Observable<ResponseI>
  {
    let direccion = this.url + "login";//Entre comillas el nombre del WS en el API

    return this.http.post<ResponseI>(direccion,form);

  }
}
