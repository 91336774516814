<!-- Banner Area -->
<div class="banner-area-three banner-bg">
    <div class="container">
        <div class="banner-content banner-content-three">
            <span>INMOBILIARIA LEIBNITZ</span>
            <h1>LEIBNITZ</h1>
            <p>Tu mejor opción en servicios inmobiliarios en Cancún Quintana Roo</p>
        </div>
    </div>
</div>
<!-- Banner Area End -->

<!-- Status Area -->
<div class="status-area">
    <div class="container status-bg">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="status-card">
                    <h3>Empresa Mexicana</h3>
                    <p>Somos una empresa mexicana con más de una década de experiencia, infraestructura y capacidad para comercializar remates bancarios.</p>
                    <i class='flaticon-magnifying-glass'></i>
                    <div class="status-bottom-1"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="status-card">
                    <h3>¿Qué brindamos?</h3>
                    <p>Te brindamos consultoría experta y profesional, acceso a oportunidades de inversión únicas y un servicio integral que cubre todas tus necesidades.<br></p>
                    <i class='flaticon-bank'></i>
                    <div class="status-bottom-2"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="status-card">
                    <h3>Sede física</h3>
                    <p>Mza. 2, Av. Tulum No. 318-Lt 2, 77500, Cancún, Q.R.<br><br><br></p>
                    <i class='flaticon-settings'></i>
                    <div class="status-bottom-3"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Status Area End -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <span>Nuestros servicios</span>
                    <h2>Encuentra esa propiedad que cubre tus necesidades</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="service-text">
                    <p>Si buscas un acogedor departamento, una modesta casa habitacional o una elegante residencia, nuestro servicio personalizado y el equipo experimentado en bienes raíces te brindarán mayores posibilidades de encontrar y adquirir la propiedad de tus sueños. Te acompañamos en cada paso del proceso hasta que alcances tu objetivo.</p>
                </div>
            </div>
        </div>

        <div class="row pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-balance"></i></a>
                    <h3><a routerLink="/services-details">Casa - Habitación</a></h3>
                    <p>Disponemos de una amplia variedad de inmuebles adaptados a tu presupuesto, además de ofrecerte excelentes amenidades para tu tranquilidad y confianza.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-home"></i></a>
                    <h3><a routerLink="/services-details">Residencial</a></h3>
                    <p>Si anhelas exclusividad y un aumento en tu inversión, Tu Casa Soñada te Espera. Eleva tus inversiones inmobiliarias con nuestros servicios de primera categoría.<br><br></p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-bank"></i></a>
                    <h3><a routerLink="/services-details">Departamentos</a></h3>
                    <p>Encuentra tu hogar perfecto en modernos y espaciosos departamentos en preventa, con un diseño elegante y funcional. <br><br></p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="service-shape">
        <div class="shape1"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>
<!-- Services Area End -->


<!-- Guidelines Area -->
<div class="guidelines-area-two">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12 pl-0">
                <div class="guidelines-img">
                    <img src="assets/img/img-1.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12 pr-0">
                <div class="guidelines-content-bg">
                    <div class="guidelines-content-two">
                        <h2>LEIBNITZ</h2>
                        <p>Explora nuestra gama de propiedades que se ajustan a tu presupuesto y preferencias. <br><br>
                            Te aseguramos una experiencia segura y confiable en la adquisición de tu nuevo hogar, respaldada por nuestro compromiso total contigo.
                        </p>
                        <!-- <div class="signature"><img src="assets/img/signature2.png" alt="Images"></div> -->
                        <div class="content">
                            <h3>Grupo Inmobiliario</h3>
                            <!-- <span>Founder & CEO</span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Guidelines Area End -->

<!-- Choose Area -->
<div class="choose-area-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12 pl-0">
                <div class="choose-content choose-content-bg">
                    <div class="choose-max">
                        <div class="section-title">
                            <span>LEIBNITZ</span>
                            <h2>SOMOS UNA EMPRESA LÍDER EN CONSULTORÍA CONFIABLE Y CAPACITADA PARA BRINDAR ASESORÍA ADECUADA </h2>
                            <!-- <p>¿Qué es un Remate Bancario? </p> -->
                            <a routerLink="/nosotros" class="default-btn">Conócenos</a>
                        </div>

                        <div class="row border-top">
                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-sort"></i>
                                    <div class="content">
                                        <h3>Objetivo del remate bancario</h3>
                                        <p>recuperar el capital prestado junto con los intereses ordinarios y moratorios, aprovechando la propiedad para asegurar el retorno de la inversión.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-briefcase"></i>
                                    <div class="content">
                                        <h3>Desarrollo del remate bancario </h3>
                                        <p>se ejecuta mediante un procedimiento legal que surge cuando un crédito previamente concedido no es pagado, transformando la deuda en una oportunidad para recuperar la inversión.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-analytics"></i>
                                    <div class="content">
                                        <h3>Cesión de derechos </h3>
                                        <p>Consiste en la venta de los derechos para cobrar un crédito. Generalmente, estos créditos ya están en proceso judicial debido a la falta de pago por parte del deudor, convirtiendo la deuda en una oportunidad para quienes buscan recuperar la inversión.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-chess-pieces"></i>
                                    <div class="content">
                                        <h3>Bienes adjudicados </h3>
                                        <p>consiste en la venta de los derechos para cobrar un crédito. Generalmente, estos créditos ya están en proceso judicial debido a la falta de pago por parte del deudor, convirtiendo la deuda en una oportunidad para quienes buscan recuperar la inversión.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12 pr-0">
                <div class="choose-img-3">
                    <img src="assets/img/img-section-1.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Video Area -->
<div class="video-area video-bg2">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="video-btn">
                    <a class="video-play-btn"><i class="flaticon-play-button"></i></a>
                    <iframe width="325" height="250" src="https://www.youtube.com/embed/8A2qOp_2yD8?si=fHEJFVT5z1oIubIN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="video-content">
                    <div class="section-title">
                        <h2>EL HOGAR DE TUS SUEÑOS A UN PASO DE TI </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid mr-0 pr-0">
        <div class="guidelines-area guidelines-max guidelines-bg2">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="guidelines-content">
                        <h2>LOS REMATES BANCARIOS A UN SOLO CLICK DE DISTANCIA </h2>
                         <br>
                        <div class="content">
                            <h3>!Propiedades increíbles en tu área!</h3>
                            <span>LEIBNITZ</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br>

    </div>
</div>
<!-- Video Area End -->


<!-- <video autoplay muted loop id="myVideo" class="alto">
    <source src="https://www.youtube.com/embed/UwyLsxPkfoI?si=ccVCNFGSwhDnQp6N" type="video/mp4">
    Your browser does not support HTML5 video.
</video> -->


<!-- <iframe width="100%" height="315" src="https://www.youtube.com/embed/UwyLsxPkfoI?si=ccVCNFGSwhDnQp6N?autoplay=1&loop=1&mute=1&controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen allow="autoplay" ></iframe> -->
<!-- <iframe width="100%" height="100%" src="https://www.youtube.com/embed/UwyLsxPkfoI?si=ccVCNFGSwhDnQp6N?autoplay=1&loop=1&mute=0&controls=1" frameborder="0" allow="autoplay"  encrypted-media allowfullscreen></iframe> -->
<!-- <iframe width="100%" height="100%" src="https://www.youtube.com/embed/UwyLsxPkfoI?si=ccVCNFGSwhDnQp6N?autoplay=1&mute=1&loop=1" frameborder="0" allow="autoplay" encrypted-media allowfullscreen></iframe> -->
