<div class="container">
    <div class="row">
      <div class="col-lg-12">

              <!-- <div class="row mb-3">
                <video autoplay muted loop id="myVideo" class="alto">
                  <source src="{{url2 + id_inmueble + '.mp4'}}" type="video/mp4">
                  Your browser does not support HTML5 video.
                </video>
              </div> -->
              <div class="row mb-4">
                    <div class="col-lg-6">
                        <button class="btn btn-warning" onclick="history.back()" style="color: aliceblue;">Volver a la lista de inmubeles</button><br><br>
                    </div>
              </div>


              <div class="row mb-4">
                  <div class="center-container">
                      <img class="center-son contenedor-imagen" src="{{url + id_inmueble + '.png'}}" >
                  </div>
              </div>


      </div>
    </div>
  </div>
