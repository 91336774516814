import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-detalle-propiedad',
  standalone: true,
  imports: [],
  templateUrl: './dialog-detalle-propiedad.component.html',
  styleUrl: './dialog-detalle-propiedad.component.scss'
})
export class DialogDetallePropiedadComponent {

}
