<!-- Inner Banner -->
<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title text-center">
            <!-- <h3>Servicios</h3> -->
            <h1 class="display-1 custom-title">Nuestros Servicios</h1>
            <ul>
                <li>LEIBNITZ</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="service-widget-title">
            <div class="section-title text-center">
                <h2>Ventajas de Invertir en Remates Bancarios con Nosotros</h2>
                <p>Invertir en remates bancarios puede ser una excelente oportunidad, ya que te permite adquirir inmuebles a precios inferiores a los del mercado.</p>
            </div>
        </div>

        <div class="row pt-45 pb-20">
            <div class="col-lg-3 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-balance"></i></a>
                    <h3>Acceso exclusivo a propiedades</h3>
                    <p>Brindamos a nuestros clientes acceso exclusivo a propiedades que aún no están disponibles en el mercado, con precios notablemente reducidos.<br><br><br><br></p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-sort"></i></a>
                    <h3><a routerLink="/services-details">Verificación de legalidad y estado del inmueble </a></h3>
                    <p>Cada propiedad se somete a un exhaustivo proceso de verificación legal y revisión de su estado físico antes de ser incorporada a nuestro portafolio.<br><br><br></p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3><a routerLink="/services-details">Asesoría personalizada</a></h3>
                    <p>Benefíciate de la experiencia de nuestros expertos en bienes raíces para tomar decisiones bien fundamentadas que maximicen los retornos y se adapten a tus necesidades particulares.<br><br></p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-chess-pieces"></i></a>
                    <h3><a routerLink="/services-details">Trámites Ágiles y confiables</a></h3>
                    <p>Nos encargamos de todo el papeleo y los trámites legales para la compra de propiedades, asegurando que el proceso sea ágil y sin contratiempos, para que puedas disfrutar de tu nueva propiedad sin preocupaciones.</p>
                </div>
            </div>


        </div>
    </div>
</div>
<!-- Services Area End -->





<div class="guidelines-area-two">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12 pl-0">
                <div class="guidelines-img">
                    <img src="assets/img/banner14.jpg" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12 pr-0">
                <div class="guidelines-content-bg">
                    <div class="guidelines-content-two">
                        <h2>RECUPERACIONES BANCARIAS</h2>
                        <p>Descubre las últimas tendencias residenciales y futuras para identificar la propiedad perfecta para ti. Obtén recomendaciones excepcionales en bienes raíces residenciales y una valuación precisa acompañada de asesoría experta.

                        <br><br>Accede a oportunidades irresistibles en terrenos y propiedades residenciales, y optimiza tus rendimientos con nuestra guía experta.</p>
                        <div class="content">
                            <h3>LEIBNITZ</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="service-widget-title">
            <div class="section-title text-center">
                <!-- <span>Our Services</span> -->
                <h3>Solicita información y deja que nuestros asesores te presenten las mejores opciones, combinando la calidez y confianza únicas que solo <b>LEIBNITZ</b> puede ofrecerte.</h3>
                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit . Pellentesque lacinia vitae libero eu suscipit. Morbi porttitor vel risus at urna semper Suspendisse sodales urna erat, suscipit consequat nisl egestas nec.</p> -->
            </div>
        </div>

        <div class="row pt-45 pb-20">
            <div class="col-lg-3 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-balance"></i></a>
                    <h3>Profesionalismo<br><br></h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-sort"></i></a>
                    <h3>Asesoramiento<br><br></h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3>Servicio de calidad<br><br></h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-chess-pieces"></i></a>
                    <h3>Variedad de propiedades</h3>
                </div>
            </div>
        </div>
    </div>
</div>
