<div>
    <section class="vh-100">
      <div class="container-fluid">
        <div class="row">

          <!-- Imagen -->
          <div class="col-sm-8 px-0 d-none d-sm-block">
            <img src="assets/images/banner11.jpg" alt="Login image" class="w-100 vh-100 efecto" style="object-fit: cover; object-position: left;">
            <div class="banner">
              <br>
              <h1 class="titulo">MODULO DE ADMINISTRACIÓN </h1>
            </div>
          </div>

          <mat-spinner class="spinnercss" *ngIf="spinner == true"></mat-spinner>

          <!-- Formulario -->
          <div class="col-sm-4 text-black fondo-form">


                  <div class="px-5 ms-xl-4" style="text-align: center;">
                    <img src="assets/images/logo.png" class="img_login">
                  </div>

                  <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">

                    <form [formGroup]!="loginForm" (ngSubmit)="onLogin(loginForm.value)" style="width: 23rem;">
                        <h3 class="fw-normal mb-3 pb-3 titulo-login">
                          <b>GRIMPU</b>
                          <hr class="custom-hr">
                        </h3>

                        <div class="form-outline mb-4">
                          <mat-form-field class="full-width-input" style="text-align: center;" appearance="outline">
                            <mat-label>Usuario</mat-label>
                            <input matInput placeholder="Ingresa tu usuario" formControlName="name" required>
                            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                            <mat-hint>Usuario </mat-hint>
                          </mat-form-field>
                        </div>

                        <div class="form-outline mb-4">
                          <mat-form-field class="full-width-input" style="text-align: center;" appearance="outline">
                            <mat-label>Contraseña</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-hint>Contraseña</mat-hint>
                          </mat-form-field>
                        </div>

                        <div class="pt-1 mb-4">
                          <!--<button class="btn btn-info btn-lg btn-block" type="button">Entrar</button>-->
                          <button mat-raised-button class="button" type="submit">Entrar</button>
                        </div>
                    </form>
                  </div>

                  <div class="alert alert-danger" *ngIf = "errorStatus">
                      <p>{{ errorMsg }}</p>
                  </div>
              </div>
          </div>

      </div>
    </section>
  </div>
