import { Component, Inject, OnInit, } from '@angular/core';
import { conexion } from 'src/app/conexion';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detalle-propiedad',
  standalone: true,
  imports: [],
  templateUrl: './detalle-propiedad.component.html',
  styleUrl: './detalle-propiedad.component.scss'
})
export class DetallePropiedadComponent implements OnInit  {

    data: any;
    id_inmueble: any;
    spinner: boolean = true;
    empleados: any;
    url:any;
    url2:any;


  constructor(
    private router: Router,
    private rutas: ActivatedRoute,
  ) { }

  ngOnInit(): void
  {
    this.id_inmueble = this.rutas.snapshot.paramMap.get("id");
    console.log("Id del inmuebkle: ", this.id_inmueble)
    this.url = conexion.url_img + "/inmuebles/detalles/";
    this.url2 = conexion.url_img + "/inmuebles/videos/";
  }


}
