import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { LoginI } from 'src/app/models/login.interface';
import { ResponseI } from 'src/app/models/response.interface';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {


  hide = true;

  loginForm = new FormGroup({
    name : new FormControl('', Validators.required),
    password : new FormControl('', Validators.required)
  })

  errorStatus:boolean = false;
  errorMsg:any = "";
  miToken: any;
  spinner: boolean = false;


  constructor(
    private LoginService? :LoginService,
    private router? : Router,
  ) { }

  ngOnInit(): void
  {
    this.getLocalStorage();

  }


  getLocalStorage()
  {
    //if(localStorage.getItem('token')){
      //this.router.navigate(['main']);

    //}
    this.miToken = localStorage.getItem('token');
    console.log("Este es el Token en LOcalStorage: ", this.miToken);
    return this.miToken;
  }



  onLogin(form:any)
  {
    //console.log("Esto trae form: ", form);
    this.spinner = true;
    this.LoginService.loginByUser(form).subscribe(data =>{
      console.log("EL Response: ", data);

      let dataResponse:ResponseI = data;

      if(dataResponse.status == "ok")
      {
        //console.log("EL TOKEEEN: ",  dataResponse.result.token);
        this.spinner = false;
        localStorage.setItem("token", dataResponse.result.token);
        localStorage.setItem("id_usuario", dataResponse.result.id_usuario);
        this.router.navigate(['modulo-administracion']);
      }
      else{
        this.errorStatus = true;
        this.errorMsg = dataResponse.result.mensaje;
        this.spinner = false;
      }


    })
  }// End on Login

}//End class Login
