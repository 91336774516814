<!-- Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3 class="custom-title">Propiedades disponibles</h3>
            <ul>
                <li>GRUPO INMOBILIARIO LEIBNITZ</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Case Area -->
<div class="case-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span><b>Contactanos</b></span>
            <h2>Lista de inmuebles</h2>
            <p> Tu nueva casa a un click de distancia. </p>
        </div>

        <div class="row pt-45">

            @for (item of dataAxios; track $index) {
                <div class="col-lg-4 col-md-6">
                    <div class="case-card">
                        <a routerLink="/projects-details"><img src="{{url + item.id_inmueble + '.png' }}" alt="Images"></a>
                        <div class="content">
                            <h3><a routerLink="/projects-details">{{ item.s_colonia }}</a></h3>
                            <mat-card-subtitle>[{{ item.id_inmueble }}] {{ item.s_tipo_inmueble }} |
                                <label style="color: green; font-weight: bold;">{{ item.s_estatus }} </label>
                              </mat-card-subtitle>
                            <p>{{ item.s_direccion }}</p>
                            <p><b>{{ item.s_estado_republica }}</b></p>
                            <h3>${{ item.n_total }}</h3>
                            <!-- <a class="case-more" (click)="detalles(item.id_inmueble)"><i class='flaticon-double-right-arrows-angles'></i></a> -->
                            <!-- <button (click)="dialogDetallePropiedad(item.id_inmueble)" class="btn btn-danger">Detallles</button> -->
                        </div>
                    </div>
                </div>
            }


            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/case-study" class="page-numbers">2</a>
                    <a routerLink="/case-study" class="page-numbers">3</a>
                    <a routerLink="/case-study" class="next page-numbers">Next <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Case Area End -->
