import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { conexion } from '../../conexion';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})

export class InmueblesService
{

  constructor(private http: HttpClient) { }



  async getInmueblesAXIOS()
  {
      const responseEJM = await axios.get('https://api-gpu.grinpu.com/api/propiedades_por_inmobiliaria/2',
        {
          headers:{
            'Content-Type' : 'application/json',
          }
        }
      )
      const content = await responseEJM.data;

      return Promise.resolve(content);
  }


  All(s_token: string)
  {
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': s_token
     });
    let params = { headers: headers };
    let url = conexion.url + 'inmuebles'
    return this.http.get(url, params);
  }

  GetById(s_token:string, id: number)
  {
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Token': s_token
     });
    let params = { headers: headers };
    let url = conexion.url + 'inmuebles/'+id;
    return this.http.get(url, params);
  }


  Post(s_token:string, data: any)
  {
    let headers = new HttpHeaders({
        'Content-Type': 'application/json; multipart/form-data',
        'Token': s_token
     });
     let url = conexion.url + 'inmuebles'
    let options = { headers: headers };

    return this.http.post(url, data, options);
  }

  Update(s_token:string, data: any, id: number)
  {
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Token': s_token
     });
    let options = { headers: headers };
    let url = conexion.url + 'inmuebles/'+ id
    return this.http.put(url, data, options);
  }


  SendEmail(s_token:string, data: any)
  {
    let headers = new HttpHeaders({
        'Content-Type': 'application/json; multipart/form-data',
        'Token': s_token
     });
     let url = conexion.url + 'enviar_correo'
    let options = { headers: headers };

    return this.http.post(url, data, options);
  }


  GetPropiedadesPorInmobiliairia(s_token:string, id: number)
  {
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Token': s_token
     });
    let params = { headers: headers };
    let url = conexion.url + 'propiedades_por_inmobiliaria/'+id;
    return this.http.get(url, params);
  }


}
