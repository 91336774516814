<!-- Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contáctanos</h3>
            <ul>
                <li><a routerLink="/">Será un placer atenderte</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Section -->
<div class="contact-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bxs-phone'></i>
                    <div class="content">
                        <h3>Teléfono</h3>
                        <!-- <p><a href="tel:+61283171401">+612-831-714-01</a></p> -->
                        <span><a href="tel:+61283175421">+521-55-743-385-32</a></span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bx-map'></i>
                    <div class="content">
                        <h3>Dirección</h3>
                        <p>Mza. 2, Av. Tulum No. 318-Lt 2, 77500, Cancún, Q.R.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4 offset-lg-0 offset-md-0 offset-sm-3">
                <div class="contact-card">
                    <i class='bx bx-envelope'></i>
                    <div class="content">
                        <h3>Email</h3>
                        <p><a href="mailto:contacto&#64;leibnitzinmobiliaria.com">contacto&#64;leibnitzinmobiliaria.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>LEIBNITZ INMOBILIARIA</h2>
                <p>Tu mejor opción en servicios inmobiliarios y soluciones en recuperaciones bancarias.</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contáctanos</h2>
                            <p>Nuestos gestores, se podrán en contacto contigo para brindarte una asesoría personalizada. </p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Nombre">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Correo">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Teléfono">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Asunto">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Mensaje"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Enviar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Map Area -->
<!-- <div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d424146.10263665376!2d150.65179679116446!3d-33.847356725232856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae665e892fdd%3A0x3133f8d75a1ac251!2sSydney%20Opera%20House!5e0!3m2!1sen!2sbd!4v1599988407815!5m2!1sen!2sbd"></iframe>
</div> -->
<!-- Map Area End -->
