<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Images"></a>
                    </div>
                    <p>Grupo Inmobiliario LEIBNITZ. "Tu aliado ideal en servicios inmobiliarios y soluciones efectivas para recuperaciones bancarias."
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/people/Leibnitz-Inmobiliaria/61560858956121/?mibextid=LQQJ4d" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>  -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="footer-widget">
                    <h3>Atajos</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/nosotros">Nosotros</a></li>
                        <li><a routerLink="/servicios">Servicios</a></li>
                        <li><a routerLink="/propiedades">Propiedades</a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Contáctanos</h3>

                    <ul class="footer-list-two">
                        <li>
                            <i class='bx bx-time'></i>
                            Lun - Vie: 8.00am - 6.00pm
                        </li>
                        <li>
                            <i class='bx bx-location-plus'></i>
                            Mza. 2, Av. Tulum No. 318-Lt 2, 77500, Cancún, Q.R.
                        </li>
                        <li>
                            <i class='bx bx-phone'></i>
                            <a href="tel:+5215574338532">+5215574338532</a>
                        </li>
                        <li>
                            <i class='bx bxs-envelope'></i>
                            <a href="mailto:contacto&#64;leibnitzinmobiliaria.com">contacto&#64;leibnitzinmobiliaria.com</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-2">
                    <h3>Escríbenos</h3>

                    <div class="newsletter-area">
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Tu correo*" name="EMAIL">
                            <button class="default-btn" type="submit">Enviar  </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>Created by TrendUp MKT México</p>
        </div>
    </div>
</div>
<!-- Copy-Right Area End -->
