<!-- Inner Banner -->
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Nosotros</h3>
            <ul>
                <li>GRUPO INMOBILIARIO LEIBNITZ</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img about-img-before">
                    <img src="assets/img/somos1.png" alt="Images" style="border-radius: 20px 20px 20px 20px;">

                    <div class="about-img-small">
                        <img src="assets/img/somos2.png" alt="Images" style="border-radius: 20px 20px 20px 20px; box-shadow: inset 5em 1em gold;">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>¿Quiénes somos?</h2>
                    <h3><b>10+</b> años de experiencia</h3>
                    <p>En INMOBILIARIA <b>LEIBNITZ</b>, llevamos más de 10 años liderando el sector inmobiliario con especialización en remates bancarios. Durante una década de logros y asociaciones con las principales instituciones financieras del país, hemos transformado las oportunidades de inversión en éxitos tangibles para numerosos mexicanos.</p>
                    <p>Nuestra experiencia y reputación nos posicionan como una empresa de total confianza. Contamos con un equipo de más de 70 expertos, incluyendo Agentes de Bienes Raíces y Abogados Financieros, todos dedicados a ofrecerte una asesoría inigualable en el mundo de los remates bancarios. Aquí, tu inversión está en las mejores manos.</p>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>10+</h3>
                                    <span>Experiencia</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-team"></i>
                                <div class="content">
                                    <h3>70+</h3>
                                    <span>Integrantes</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>50+</h3>
                                    <span>Propiedades</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-polygon-shape">
        <img src="assets/img/shape/shape10.png" alt="Images">
    </div>
</div>
<!-- About Area End -->

<!-- Choose Area -->
<div class="choose-area-two choose-bg pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <h2>Calidad y servicio</h2>
                        <p>En <b>LEIBNITZ</b> entendemos la importancia de tus inversiones y nos enfocamos en brindarte la mejor calidad en nuestros servicios. Nos enorgullece ofrecer un respaldo profesional constante, posicionándonos como tus asesores de confianza en el mercado inmobiliario.</p>
                        <!-- <a routerLink="/contact" class="book-btn">Book A Service</a> -->
                    </div>

                    <div class="row border-top">

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-chess-pieces"></i>
                                <div class="content">
                                    <h3>Asesores capacitados</h3>
                                    <p>Contamos con el personal más altamente capacitado para presentarte las mejores opciones del mercado.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>Tranquilidad</h3>
                                    <p>Disfrutarás de una total tranquilidad al saber que los aspectos legales y financieros están en manos de expertos profesionales.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-analytics"></i>
                                <div class="content">
                                    <h3>Confianza</h3>
                                    <p>Confiarás en que estás haciendo inversiones inteligentes y seguras, respaldado por la experiencia y el apoyo de verdaderos expertos.</p>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-sort"></i>
                                <div class="content">
                                    <h3>Satisfacción</h3>
                                    <p>Te sentirás plenamente satisfecho al saber que estás aprovechando oportunidades de inversión únicas, seleccionadas con precisión y evaluadas para ofrecerte el máximo potencial. </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="choose-img-2">
                    <img src="assets/img/large1.png" alt="Images">

                    <div class="choose-dots">
                        <img src="assets/img/choose/choose-dots.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->


<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>EXPANDE TU PATRIMONIO Y ASEGURA UN MEJOR FUTURO </h2>
                <p>Dedicados a la asesoría legal e inmobiliaria, juntos por un mejor futuro.  <br>
                    <b>GRUPO INMOBILIARIO LEIBNITZ</b>
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area End -->
