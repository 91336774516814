<!-- Banner Area -->
<div class="banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <span>Since 2002</span>
                    <h1>Good Planning Means Success In Business</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce molestie varius leo, non laoreet purus viverra id.</p>
                    <div class="banner-btn">
                        <a routerLink="/contact" class="contact-btn">Contact Us</a>
                        <a routerLink="/" class="get-btn">Get A Quote</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-img">
                    <img src="assets/img/home-one.jpg" alt="Images">

                    <div class="dots">
                        <img src="assets/img/shape/dots.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape">
        <div class="shape-icon"><i class="flaticon-graph"></i></div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Special Area -->
<div class="special-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="special-card">
                    <a routerLink="/services-details"><img src="assets/img/special/special-img1.jpg" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/services-details">Complete Analysis</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis. </p>
                        <a routerLink="/services-details" class="learn-btn text-center">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="special-card">
                    <a routerLink="/services-details"><img src="assets/img/special/special-img2.jpg" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/services-details">Well Approach</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis. </p>
                        <a routerLink="/services-details" class="learn-btn text-center">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="special-card">
                    <a routerLink="/services-details"><img src="assets/img/special/special-img3.jpg" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/services-details">Save Your Tax</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis. </p>
                        <a routerLink="/services-details" class="learn-btn text-center">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Special Area End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about/about-img1.jpg" alt="Images">

                    <div class="about-img-small">
                        <img src="assets/img/about/about-img2.jpg" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>We Are Standing With <b>30+</b> Years Of Experience</h2>
                    <h3>Trusted And Effective Business Consulting Service Provider</h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis ullamcorper. Ut volutpat dignissim arcu sit amet finibus. Aenean pulvinar ornare erat. Donec a massa sed ipsum fringilla rhoncus.</p>
                    <p>Dignissim arcu sit amet finibus.Aenean pulvinar ornare erat. Donec a massa sed ipsum fringilla rhoncus.</p>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>1200+</h3>
                                    <span>Award Winner</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-team"></i>
                                <div class="content">
                                    <h3>30+</h3>
                                    <span>Years Experience</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>5000+</h3>
                                    <span>Business Guide</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="about-btn">
                        <a routerLink="/about" class="learn-btn">Learn More</a>
                        <a routerLink="/contact" class="get-btn">Get A Quote</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<!-- Video Area -->
<div class="video-area video-bg1">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="video-btn">
                    <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=11s" class="video-play-btn popup-btn"><i class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="video-content">
                    <div class="section-title">
                        <span>Intro Video</span>
                        <h2>Explore Us Watch This Full Video and Know Us</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="guidelines-area guidelines-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="guidelines-content">
                        <h2>A Proper Guideline Can Make Your Business Successful</h2>
                        <p>Proin magna felis, tincidunt eget lectus id, maximus ultricies eros. Aliquam eu lacus vitae nulla faucibus mollis. Proin velit mi, molestie commodo neque laoreet a.Fusce cursus iaculis elit vitae auctor. Mauris venenatis vestibulum eros, sed turpis molestie ac. Nunc convallis nisi in maximus tincidunt.</p>

                        <div class="signature">
                            <img src="assets/img/signature.png" alt="Images">
                        </div>

                        <div class="content">
                            <h3>Smithy Karnes</h3>
                            <span>Founder & CEO</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Video Area End -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <span>Our Services</span>
                    <h2>Our Best Consulting Services Make You To Reach Your Goal</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="service-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit . Pellentesque lacinia vitae libero eu suscipit. Morbi porttitor vel risus at urna semper Suspendisse sodales urna erat, suscipit consequat nisl egestas nec.</p>
                </div>
            </div>
        </div>

        <div class="row pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-balance"></i></a>
                    <h3><a routerLink="/services-details">Solicitory</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-sort"></i></a>
                    <h3><a routerLink="/services-details">Business Planning</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3><a routerLink="/services-details">Project Managment</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-chess-pieces"></i></a>
                    <h3><a routerLink="/services-details">Strategy</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-briefcase-1"></i></a>
                    <h3><a routerLink="/services-details">Business Consulting</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a routerLink="/services-details">Human Resources</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>
        </div>

        <div class="service-view-btn text-center">
            <a routerLink="/services-1" class="view-btn">View More</a>
        </div>
    </div>
</div>
<!-- Services Area End -->

<!-- Choose Area -->
<div class="choose-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="section-title">
                    <span>Why Choose Us</span>
                    <h2>Trusted Consulting Company To Give You Right Guideline</h2>
                </div>

                <div class="tab choose-tab">
                    <ul class="tabs">
                        <li><a href="#">Our Mission</a></li>
                        <li><a href="#">Our Vission</a></li>
                        <li><a href="#">Our Value</a></li>
                    </ul>

                    <div class="tab_content current active pt-45">
                        <div class="tabs_item current">
                            <div class="choose-item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="choose-item-img">
                                            <img src="assets/img/choose/choose-img1.jpg" alt="Images">
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-12">
                                        <div class="choose-item-content">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sapien orci, varius quis rutrum vel, pellentesque sit amet nisi. Suspendisse placerat posuere massa, ut consectetur est ornare sed. Vivamus enim turpis, blandit.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="choose-item-list">
                                    <ul>
                                        <li><i class='bx bx-check'></i> Integrated Strategic Approach To Reach You In Your Goal</li>
                                        <li><i class='bx bx-check'></i> Measurable Performance Doing In Those Years</li>
                                        <li><i class='bx bx-check'></i> More Than 20 Years Of Working Experience In This Sector</li>
                                        <li><i class='bx bx-check'></i> Trusty, Reliable And Responsive Service Provider</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item current">
                            <div class="choose-item">
                                <div class="choose-item-list">
                                    <ul>
                                        <li><i class='bx bx-check'></i> Integrated Strategic Approach To Reach You In Your Goal</li>
                                        <li><i class='bx bx-check'></i> Measurable Performance Doing In Those Years</li>
                                        <li><i class='bx bx-check'></i> More Than 20 Years Of Working Experience In This Sector</li>
                                        <li><i class='bx bx-check'></i> Trusty, Reliable And Responsive Service Provider</li>
                                    </ul>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="choose-item-img">
                                            <img src="assets/img/choose/choose-img1.jpg" alt="Images">
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-12">
                                        <div class="choose-item-content">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sapien orci, varius quis rutrum vel, pellentesque sit amet nisi. Suspendisse placerat posuere massa, ut consectetur est ornare sed. Vivamus enim turpis, blandit.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item current">
                            <div class="choose-item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="choose-item-img">
                                            <img src="assets/img/choose/choose-img1.jpg" alt="Images">
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-12">
                                        <div class="choose-item-content">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sapien orci, varius quis rutrum vel, pellentesque sit amet nisi. Suspendisse placerat posuere massa, ut consectetur est ornare sed. Vivamus enim turpis, blandit.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="choose-item-list">
                                    <ul>
                                        <li><i class='bx bx-check'></i> Integrated Strategic Approach To Reach You In Your Goal</li>
                                        <li><i class='bx bx-check'></i> Measurable Performance Doing In Those Years</li>
                                        <li><i class='bx bx-check'></i> More Than 20 Years Of Working Experience In This Sector</li>
                                        <li><i class='bx bx-check'></i> Trusty, Reliable And Responsive Service Provider</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="choose-img">
                    <img src="assets/img/choose/choose-img2.jpg" alt="Images">
                    <div class="images">
                        <img src="assets/img/choose/choose-img3.jpg" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Solutions Area -->
<div class="solutions-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="solutions-content">
                    <div class="section-title">
                        <span>Get Solutions</span>
                        <h2>Need A Guideline, Searching For Consulting?</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="solutions-btn">
                    <a routerLink="/contact" class="default-btn">Get A Quote Here</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Solutions Area End -->

<!-- Project Area -->
<div class="project-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="project-title">
                    <div class="section-title">
                        <span>Our Projects</span>
                        <h2>Some Of Our Projects Work Where We Helped</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="project-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae pellentesque.</p>
                </div>
            </div>
        </div>

        <div class="tab project-tab">
            <ul class="tabs">
                <li><a href="#">Solicitory</a></li>
                <li><a href="#">Strategy</a></li>
                <li><a href="#">Human Resources</a></li>
                <li><a href="#">Business Planning</a></li>
                <li><a href="#">Project Management</a></li>
            </ul>

            <div class="tab_content current active pt-45">
                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Strategy</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Human Resources</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Strategy</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a href="project-details.html">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="projects-details.html"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Project Area End -->

<!-- Client Area -->
<div class="client-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="client-title">
                    <div class="section-title">
                        <span>Clients Review</span>
                        <h2>Our Lovely Clients Get Helped From Our Company</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="client-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae pellentesque.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="client-item">
                    <div class="client-img">
                        <img src="assets/img/client/client-img1.png" alt="Images">
                    </div>

                    <div class="content">
                        <h3>Thomas Smith</h3>
                        <span>CEO Of LTD Company</span>
                        <ul>
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <p>Suspendisse pretium venenatis nunc. Sed molestie lacus tellus, id placerat urna tristique sit amet .</p>
                        <i class="flaticon-quote quote"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="client-item">
                    <div class="client-img">
                        <img src="assets/img/client/client-img2.png" alt="Images">
                    </div>

                    <div class="content">
                        <h3>Angelin Hena</h3>
                        <span>CEO Of Gloz</span>
                        <ul>
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <p>Suspendisse pretium venenatis nunc. Sed molestie lacus tellus, id placerat urna tristique sit amet .</p>
                        <i class="flaticon-quote quote"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="client-item">
                    <div class="client-img">
                        <img src="assets/img/client/client-img3.png" alt="Images">
                    </div>

                    <div class="content">
                        <h3>James Nilson</h3>
                        <span>Manager Of Gloz</span>
                        <ul>
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <p>Suspendisse pretium venenatis nunc. Sed molestie lacus tellus, id placerat urna tristique sit amet .</p>
                        <i class="flaticon-quote quote"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Client Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Consulting Quote</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->