import { InmueblesService } from 'src/app/services/inmuebles/inmuebles.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { conexion } from 'src/app/conexion';
import { FormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';
import { Router } from '@angular/router';

//--------------------- Dialog ---------------------
import { MatDialog } from '@angular/material/dialog';
import { DialogDetallePropiedadComponent } from './dialog-detalle-propiedad/dialog-detalle-propiedad.component';



@Component({
  selector: 'app-propiedades',
  templateUrl: './propiedades.component.html',
  styleUrl: './propiedades.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    NgFor
  ],
})
export class PropiedadesComponent implements OnInit{


  data: any;
  dataAxios: any;
  url:any;
  spinner : boolean = true;

  constructor(
    private InmueblesService : InmueblesService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    //this.getInmuebles();
    this.getInmueblesAxios();
    this.url = conexion.url_img + "/inmuebles/redimensionados/";
  }


//   async getInmuebles()
//     {
//       this.InmueblesService.All('').subscribe
//       (data =>
//         {
//           this.data = data;
//           console.log("Lista de Inmuebles...");
//           console.log(this.data);
//           this.spinner = false;

//         },

//         error => {
//           console.log('oops', error)
//           Swal.fire('Adevertencia', 'Error al cargar información de los inmuebles, Consulte con el administrador', 'warning');
//         }
//       )
//     }

    async getInmueblesAxios()
    {
    this.InmueblesService.getInmueblesAXIOS()
    .then(response =>{

        this.dataAxios = response;
        console.log("Respuesta en el front para AXIOS: ");
        console.log(this.dataAxios);

        return this.dataAxios;
    })
    }




    async GetPropiedadesPorInmobiliairia()
    {
      this.InmueblesService.GetPropiedadesPorInmobiliairia("",2).subscribe
      (data =>
        {

          this.dataAxios = data;
          console.log("Respuesta en el front para AXIOS: ");
          console.log(this.dataAxios);

          return this.dataAxios;
      })
    }


  dialogDetallePropiedad(id_inmueble:any)
  {
    this.dialog.open(DialogDetallePropiedadComponent,  {data: id_inmueble });
  }

  detalles(id_servicio:any)
  {
    this.router.navigate(['detalle-propiedad/'+id_servicio]);
  }



}
